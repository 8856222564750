import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageWrapper,
  TextWrapper,
  HeadingWrapper,
  FlexWrapper,
  FlexItem,
} from "../components/contentwrappers"
import ContactForm from "../components/contact-form/contact-form"
import { FaBuilding, FaPhone, FaEnvelope } from "react-icons/fa"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"

import Section from "../components/section"

const ImportantInformationPage = props => (
  <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
    <SEO title="Important Information" />
    <BackgroundImage
      fluid={props.data.privacyPolicyImage.childImageSharp.fluid}
      img="Man on laptop"
    >
      <Header headerText="Important Information"></Header>
    </BackgroundImage>
    <Section>
      <h2>Risk Warning</h2>

      <p>
        This website is designed to provide information about TAG Financial
        Planning Ltd. Any reference to investment products are for educational
        purposes and should not be considered investment advice or an offer of
        any security for sale. Such references do not represent a recommendation
        of any particular security, strategy or investment product. Information
        contained on this website has been obtained from sources believed to be
        reliable, but is not guaranteed.
      </p>

      <p>
        Please be aware that the value of investments and any income will
        fluctuate (this may partly be the result of exchange rate fluctuations)
        and investors may not get back the full amount invested.
      </p>

      <p>
        Past performance is not a guide to future returns. Current tax levels
        and reliefs may change. Depending on individual circumstances, this may
        affect investment returns.
      </p>

      <h2>Privacy Policy</h2>

      <p>
        At TAG Financial Planning Ltd, we’re committed to protecting and
        respecting your privacy. By using our website, you’re agreeing to be
        bound by this Policy.
      </p>

      <p>
        Our Privacy Policy explains when and why we collect personal information
        about people who visit our website, how we use it, the conditions under
        which we may disclose it to others and how we keep it secure.
      </p>

      <p>
        TAG Financial Planning Ltd acts as a controller for the personal
        information you provide.
      </p>

      <p>
        For more information, please check our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </p>

      <h2>Complaints Policy</h2>

      <p>
        In the event that you ever have to complain, you should contact: The
        compliance officer at Stonyroyd House, 8 Cumberland Road, Leeds, West
        Yorkshire, LS6 2EF or by phone 0113 224 2800.
      </p>

      <p>
        For more information, please check our{" "}
        <Link to="/complaints-policy">Complaints Policy</Link>.{" "}
      </p>

      <h2 id="fos">Financial Ombudsman Service</h2>

      <p>
        The Financial Ombudsman Service is available to sort out individual
        complaints that clients and financial services businesses aren’t able to
        resolve themselves.
      </p>

      <p>
        To contact the Financial Ombudsman Service please visit{" "}
        <a
          href="https://www.financial-ombudsman.org.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.financial-ombudsman.org.uk
        </a>
        .{" "}
      </p>

      <h2>Contact Us</h2>

      <ContactForm firmName={props.pageContext.firm}></ContactForm>
      <FlexWrapper>
        <FlexItem flexMe="1.5">
          <div style={{ textAlign: "center" }}>
            <strong style={{ display: "block" }}>
              <FaBuilding style={{ marginRight: "1em" }} />
              Address
            </strong>{" "}
            Stonyroyd House, 8 Cumberland Road, Leeds, LS6 2EF
          </div>
        </FlexItem>
        <FlexItem flexMe="1.5">
          <div style={{ textAlign: "center" }}>
            <strong style={{ display: "block" }}>
              <FaEnvelope style={{ marginRight: "1em" }} />
              Email
            </strong>{" "}
            <a href="mailTo:info@tagfp.co.uk">info@tagfp.co.uk</a>
          </div>
        </FlexItem>
        <FlexItem flexMe="1.5">
          <div style={{ textAlign: "center" }}>
            <strong style={{ display: "block" }}>
              <FaPhone style={{ marginRight: "1em" }} />
              Phone
            </strong>{" "}
            0330 350 3215
          </div>
        </FlexItem>
      </FlexWrapper>
    </Section>
  </Layout>
)

export default ImportantInformationPage

export const pageQuery = graphql`
  query {
    privacyPolicyImage: file(relativePath: { eq: "privacy-policy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
